@layer base {
  .radioGroupLegend,
.inputLabel {
  margin-bottom: var(--sk-space-16);
}

.radioGroup {
  display: flex;
  gap: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

}