@layer base {
  .createCompanyAction {
  margin-left: 50%;
  transform: translateX(-50%);
}

.field {
  margin-bottom: var(--spacer-25);
}

.updateCompanyList {
  margin-top: var(--spacer-100);
  font-size: var(--sk-font-size-1);
  font-weight: var(--bold);
}

.tips {
  margin-bottom: var(--spacer-300);
}

}