@layer base {
  .RadioProcess {
  display: flex;
  flex-direction: column;
}

.RadioProcess:has(+ *) {
  margin-bottom: var(--spacer-300);
}

.SubFormContainer {
  padding: var(--spacer-300) 0 0 var(--spacer-600);
  border: 0;
}

.RadioBoxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacer-200);
}

.Label {
  display: inline-block;
  margin-bottom: var(--spacer-200);
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

.option {
  margin-top: var(--spacer-300);
}

.message {
  margin-bottom: var(--spacer-200);
}

}