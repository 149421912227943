@layer base {
  .RadioBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacer-200);
  border: solid 2px transparent;
  border-radius: var(--radius-100);
  outline: var(--border);
  text-align: center;
  cursor: pointer;
}

.RadioBox:has(input:focus-visible) {
  outline-color: var(--color-black);
  outline-width: 2px;
  box-shadow: 0 0 4px 2px var(--color-grey--200);
}

.RadioBox--checked {
  outline-color: var(--color-grey--500);
  outline-width: 2px;
}

.RadioBox__icon {
  margin-bottom: var(--spacer-200);
}

.RadioBox__main-label {
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

.RadioBox__input {
  position: absolute;
  opacity: 0;
}

.RadioBox__tagWrapper {
  margin-top: var(--spacer-100);
}

}