@layer base {
  .main {
  padding: var(--spacer-200);
  border-radius: var(--radius-200);
  background-color: var(--bgColorDetailedCard);
}

.tag {
  margin-bottom: var(--spacer-200);
  padding: var(--spacer-100) var(--spacer-200);
  border-color: transparent;
  font-size: var(--bodySmall);
  font-weight: var(--bold);
  line-height: var(--bodySmallLineHeight);
  cursor: initial;
}

.tag:hover {
  outline-color: transparent;
}

.title {
  /* add this weight in UI-Kit */
  font-weight: var(--black);
}

.title p,
.text p {
  margin: 0;
}

.title strong {
  display: inline-block;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  font-weight: inherit;
}

.title strong::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  bottom: var(--spacer-50);
  left: 0;
  width: 100%;
  height: 8px;
  background-color: var(--underlineDetailedCard);
}

.text {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.text :first-child {
  flex: 1 0 100%;
}

.text figure {
  display: inline-flex;
  align-self: center;
  width: auto;
  height: 23px;
  margin: 0;
  padding-top: var(--spacer-200);
}

.text figure + figure {
  margin-left: var(--spacer-300);
}

.text figure img {
  width: auto;
  height: 100%;
}

.button {
  margin-top: var(--spacer-300);
}

@media (--medium-and-less-only) {
  .figure {
    display: none;
  }
}

@media (--medium-viewport) {
  .main {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: var(--spacer-400) var(--spacer-800);
  }

  .tag {
    margin-bottom: var(--spacer-400);
    padding: var(--spacer-150) var(--spacer-300);
  }

  .button {
    margin-top: var(--spacer-400);
  }

  .title {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .figure {
    height: 100%;
    max-height: 250px;
    margin: 0;
  }

  .image {
    width: auto;
    height: 100%;
  }
}

/* Theme */

.title[data-theme^="dark"],
.text[data-theme^="dark"] {
  border-color: var(--color-white);
  color: var(--color-white);
}

.title[data-theme^="light"],
.text[data-theme^="light"] {
  border-color: var(--color-black);
  color: var(--color-black);
}

.tag[data-theme^="dark"] {
  background-color: var(--color-white);
  color: var(--color-black);
}

.tag[data-theme^="light"] {
  background-color: var(--color-black);
  color: var(--color-white);
}

.button[data-theme^="dark"] {
  border-color: var(--color-white);
  color: var(--color-white);
}

.button[data-theme^="light"] {
  border-color: var(--color-black);
  color: var(--color-black);
}

}