@layer base {
  .featurePackCell {
  border: none;
  border-bottom: 1px solid var(--jt-subscriptionTable-border-color);
  background-color: var(--sk-color-white);
}

.text {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

@media (--large-viewport) {
  .featurePackCell {
    border: none;
  }
}

}