@layer base {
  .main {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  align-items: center;
}

.content {
  flex: 1;
  margin: 0 var(--spacer-200) 0 0;
}

.wrapperImage {
  padding-right: var(--spacer-200);
}

.link {
  color: var(--color-black);
  text-decoration: none;
}

.link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.link:hover::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: calc(var(--spacer-150) * -1);
  left: calc(var(--spacer-150) * -1);
  width: calc(100% + var(--spacer-300));
  height: calc(100% + var(--spacer-300));
  border-radius: var(--radius-200);
  background-color: var(--color-grey--050);
}

:not(.wrapper) + .wrapper .subtitle {
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.image {
  display: block;
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: var(--radius-200);
}

@media (--small-and-less-only) {
  .shortDescription {
    display: none;
  }
}

@media (--medium-viewport) {
  :not(.wrapper) + .wrapper .subtitle {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .image {
    width: 86px;
    height: 86px;
  }
}

}