@layer modules, ui, base;
@layer base {
  .GuestFormSection_title__6FB1J {
  margin-bottom: var(--sk-space-24);
}

.GuestFormSection_note__a7t_x {
  margin-bottom: var(--sk-space-24);
}

.GuestFormSection_buttons__Ku3c1 {
  display: flex;
  gap: var(--sk-space-8);
  flex-wrap: wrap;
  margin-top: var(--sk-space-32);
}

.GuestFormSection_button__7wW6l {
  width: 100%;
}

.GuestFormSection_dataTextTitle__JsH8k {
  margin-top: var(--sk-space-24);
}

@media (min-width: 30rem) {
  .GuestFormSection_button__7wW6l {
    width: auto;
  }
}

}
@layer base {
  .DetailedCard_main__SkLW9 {
  padding: var(--spacer-200);
  border-radius: var(--radius-200);
  background-color: var(--bgColorDetailedCard);
}

.DetailedCard_tag__U6wyC {
  margin-bottom: var(--spacer-200);
  padding: var(--spacer-100) var(--spacer-200);
  border-color: transparent;
  font-size: var(--bodySmall);
  font-weight: var(--bold);
  line-height: var(--bodySmallLineHeight);
  cursor: initial;
}

.DetailedCard_tag__U6wyC:hover {
  outline-color: transparent;
}

.DetailedCard_title__wYlr9 {
  /* add this weight in UI-Kit */
  font-weight: var(--black);
}

.DetailedCard_title__wYlr9 p,
.DetailedCard_text__76NpK p {
  margin: 0;
}

.DetailedCard_title__wYlr9 strong {
  display: inline-block;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  font-weight: inherit;
}

.DetailedCard_title__wYlr9 strong::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  bottom: var(--spacer-50);
  left: 0;
  width: 100%;
  height: 8px;
  background-color: var(--underlineDetailedCard);
}

.DetailedCard_text__76NpK {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.DetailedCard_text__76NpK :first-child {
  flex: 1 0 100%;
}

.DetailedCard_text__76NpK figure {
  display: inline-flex;
  align-self: center;
  width: auto;
  height: 23px;
  margin: 0;
  padding-top: var(--spacer-200);
}

.DetailedCard_text__76NpK figure + figure {
  margin-left: var(--spacer-300);
}

.DetailedCard_text__76NpK figure img {
  width: auto;
  height: 100%;
}

.DetailedCard_button__IFbqi {
  margin-top: var(--spacer-300);
}

@media (max-width: 63.999rem) {
  .DetailedCard_figure__e9Ylq {
    display: none;
  }
}

@media (min-width: 48rem) {
  .DetailedCard_main__SkLW9 {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: var(--spacer-400) var(--spacer-800);
  }

  .DetailedCard_tag__U6wyC {
    margin-bottom: var(--spacer-400);
    padding: var(--spacer-150) var(--spacer-300);
  }

  .DetailedCard_button__IFbqi {
    margin-top: var(--spacer-400);
  }

  .DetailedCard_title__wYlr9 {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .DetailedCard_figure__e9Ylq {
    height: 100%;
    max-height: 250px;
    margin: 0;
  }

  .DetailedCard_image__z_z1U {
    width: auto;
    height: 100%;
  }
}

/* Theme */

.DetailedCard_title__wYlr9[data-theme^="dark"],
.DetailedCard_text__76NpK[data-theme^="dark"] {
  border-color: var(--color-white);
  color: var(--color-white);
}

.DetailedCard_title__wYlr9[data-theme^="light"],
.DetailedCard_text__76NpK[data-theme^="light"] {
  border-color: var(--color-black);
  color: var(--color-black);
}

.DetailedCard_tag__U6wyC[data-theme^="dark"] {
  background-color: var(--color-white);
  color: var(--color-black);
}

.DetailedCard_tag__U6wyC[data-theme^="light"] {
  background-color: var(--color-black);
  color: var(--color-white);
}

.DetailedCard_button__IFbqi[data-theme^="dark"] {
  border-color: var(--color-white);
  color: var(--color-white);
}

.DetailedCard_button__IFbqi[data-theme^="light"] {
  border-color: var(--color-black);
  color: var(--color-black);
}

}
@layer base {
  .DetailedCard_main__UvLhb {
  padding-top: var(--spacer-400);
  padding-bottom: var(--spacer-400);
  background: linear-gradient(0deg, transparent 50%, var(--color-black) 50%);
}

@media (min-width: 48rem) {
  .DetailedCard_main__UvLhb {
    padding-top: var(--spacer-800);
    padding-bottom: var(--spacer-800);
  }
}

}
@layer base {
  .CardPageLink_main__oovmq {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  align-items: center;
}

.CardPageLink_content__JeZR1 {
  flex: 1;
  margin: 0 var(--spacer-200) 0 0;
}

.CardPageLink_wrapperImage__iqjxT {
  padding-right: var(--spacer-200);
}

.CardPageLink_link__Oi5uJ {
  color: var(--color-black);
  text-decoration: none;
}

.CardPageLink_link__Oi5uJ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CardPageLink_link__Oi5uJ:hover::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: calc(var(--spacer-150) * -1);
  left: calc(var(--spacer-150) * -1);
  width: calc(100% + var(--spacer-300));
  height: calc(100% + var(--spacer-300));
  border-radius: var(--radius-200);
  background-color: var(--color-grey--050);
}

:not(.CardPageLink_wrapper__PvhDD) + .CardPageLink_wrapper__PvhDD .CardPageLink_subtitle___S3oI {
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.CardPageLink_image__VRRad {
  display: block;
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: var(--radius-200);
}

@media (max-width: 47.999rem) {
  .CardPageLink_shortDescription__4iIi5 {
    display: none;
  }
}

@media (min-width: 48rem) {
  :not(.CardPageLink_wrapper__PvhDD) + .CardPageLink_wrapper__PvhDD .CardPageLink_subtitle___S3oI {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .CardPageLink_image__VRRad {
    width: 86px;
    height: 86px;
  }
}

}
@layer base {
  .Network_container__yLIzl a[class*="jds-Pagination__activeButton"] {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.Network_filters__DFLfT {
  position: relative;
  z-index: var(--z-above-ui-kit-cards); /* to override some z-index inside Cards, below the FilterBar */
}

.Network_grid__TrWY3 {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: var(--spacer-200);
  grid-template-columns: repeat(1, 1fr);
  margin-top: var(--spacer-400);
}

.Network_image__ch_cp {
  border-radius: var(--radius-200);
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 64rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 90rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Network_grid__withError__4IZ8a {
  grid-template-columns: none;
}

}
@layer base {
  .Title_title__AcGZQ {
  margin: 0 0 var(--spacer-400);
}

@media (min-width: 48rem) {
  .Title_title__AcGZQ {
    margin-bottom: var(--spacer-700);
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .Title_title--small__tCfUd {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer base {
  /**
 * FilterBar component is used to wrap Filters and Select. As it override some styles on children, It suffers same issue
 * as Select (src/modules/uiKitOverrides/SelectFilter/Select.module.css), and need some !important in nextjs context.
 */

.FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
  margin: 0 0 -1px;
}

.FilterBar_filterBar__SW2X9 [class*="jds-Select__control"] {
  min-height: 48px !important;
}

@media (min-width: 48rem) {
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
    margin: 0 -1px -1px 0;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Input"] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Input"] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Input"] {
    border-radius: 0 !important;
  }
}

}
@layer base {
  .NewsletterContact_label__K2tQ3 {
  color: var(--color-white);
}

@media (max-width: 63.999rem) {
  .NewsletterContact_form___i_DB {
    grid-column: 1;
    margin-top: var(--spacer-300);
  }
}

@media (min-width: 48rem) {
  .NewsletterContact_fieldsWrapper__cazIz {
    display: flex;
    gap: var(--spacer-150);
    align-items: flex-end;
  }

  .NewsletterContact_field__E3D3G {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

}
@layer base {
  .ColGroup_featuresCol__ND0wD {
  width: 23.18%;
}

.ColGroup_packCol--limited__yfGel {
  width: 15.54%;
}

.ColGroup_packCol--light__74tlN,
.ColGroup_packCol--classic__sU67X {
  width: 20.44%;
}

.ColGroup_packCol--performance__qWTab {
  max-width: 20.4%;
}

}
@layer base {
  .IconPackState_main__EEmY6 {
  --jt-IconPackState-size: 1.75rem;

  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: var(--jt-IconPackState-size);
  height: var(--jt-IconPackState-size);
  padding: 0.4rem;
  border-radius: 100%;
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  font-size: 1rem;
}

.IconPackState_main--included__cHWdw {
  background-color: var(--sk-color-green-550);
}

.IconPackState_main--notIncluded__DtOrF {
  background-color: var(--sk-color-grey-200);
}

}
@layer base {
  .FeaturePackCell_featurePackCell__lonIj {
  border: none;
  border-bottom: 1px solid var(--jt-subscriptionTable-border-color);
  background-color: var(--sk-color-white);
}

.FeaturePackCell_text__XivnQ {
  display: flex;
  flex-direction: column;
}

.FeaturePackCell_bold__1492d {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

@media (min-width: 64rem) {
  .FeaturePackCell_featurePackCell__lonIj {
    border: none;
  }
}

}
@layer base {
  .RecruitAdInsert_main__YNrG1 {
  --backgroundImage: unset;

  position: relative;
  margin-bottom: var(--sk-space-80);
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-400);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -200px -200px;
  color: var(--sk-color-white);
  text-shadow: var(--sk-color-purple-500) 0 0 0.625rem;
}

.RecruitAdInsert_content__Fy34I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-32);
}

.RecruitAdInsert_cta__IG_mz {
  flex-shrink: 0;
  text-shadow: none;
}

.RecruitAdInsert_text__HQl8G {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .RecruitAdInsert_main__YNrG1 {
    padding: var(--sk-space-56) var(--sk-space-72);
  }

  .RecruitAdInsert_content__Fy34I {
    flex-direction: row;
    gap: var(--sk-space-56);
  }
}

}
@layer base {
  .ThPackHeading_main__w6a7Z {
  padding: 0;
  vertical-align: bottom;
}

.ThPackHeading_thWrapper__9Oz6s {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-32) var(--sk-space-24);
  border-bottom: solid var(--jt-subscriptionTable-border-color) 1px;
  border-top-left-radius: var(--jt-subscriptionTable-border-radius);
  border-top-right-radius: var(--jt-subscriptionTable-border-radius);
  background-color: var(--sk-color-white);
  vertical-align: bottom;
}

.ThPackHeading_main__w6a7Z:nth-child(2) .ThPackHeading_thWrapper__9Oz6s {
  border-bottom: none;
}

.ThPackHeading_title__OQUuy {
  margin-bottom: var(--sk-space-8);
}

.ThPackHeading_thBadge__K1LNb {
  margin: var(--sk-space-8) 0;
}

@media (min-width: 64rem) {
  .ThPackHeading_thWrapper__9Oz6s,
  .ThPackHeading_main__w6a7Z:nth-child(2) .ThPackHeading_thWrapper__9Oz6s {
    border: solid var(--jt-subscriptionTable-border-color) 1px;
  }
}

}
@layer base {
  .TrSection_main__FDZPT {
  text-align: left;
}

.TrSection_thCell__cE2BH,
.TrSection_thEmptyCell___smfL {
  background-color: var(--sk-color-white);
}

.TrSection_thCell__cE2BH {
  padding: var(--sk-space-8) 0;
}

.TrSection_thEmptyCell___smfL {
  padding: 0 var(--sk-space-16);
}

.TrSection_thEmptyCell___smfL,
.TrSection_tdEmptyCell__5QGNy {
  display: none !important;
}

.TrSection_colGroupTitle__d_VDO {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-450);
  color: var(--sk-color-white);
  text-align: center;
}

@media (min-width: 64rem) {
  .TrSection_thEmptyCell___smfL,
  .TrSection_tdEmptyCell__5QGNy {
    display: table-cell !important;
  }

  .TrSection_colGroupTitle__d_VDO {
    width: inherit;
    text-align: left;
  }
}

}
@layer base {
  .Nav_app-Nav__YDPug {
  width: 100%;
  margin: 0;
  padding: 0;
}

.Nav_app-Nav__item__Ns0tT {
  display: inherit;
  align-items: center;
}

.Nav_app-Nav__link__W3ONF {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: var(--sk-space-16) 0;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-size: var(--typo-body-2-font-size);
  font-weight: var(--black);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
  white-space: nowrap;
}

.Nav_app-Nav__link__W3ONF:hover {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__link--active__HuxqT {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__newTag__ZpufN {
  margin-left: var(--sk-space-8);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  .Nav_app-Nav__link__W3ONF {
    margin: 0 var(--sk-space-24);
    border-bottom: thin solid var(--themeButtonTextColor, var(--sk-color-grey-100));
  }

  .Nav_app-Nav__personasList__rsQGs {
    margin-top: var(--sk-space-16);
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem--active__HJSRA {
    display: none;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 var(--sk-space-24);
    padding: var(--sk-space-16) 0;
    border-bottom: thin solid var(--sk-color-grey-100);
    color: var(--sk-color-black);
    font-size: var(--typo-body-2-font-size);
    line-height: var(--typo-body-2-line-height);
    text-decoration: none;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Nav_app-Nav__personas__d2Ecs {
    --layout-size: var(--mj-breakpoint);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: var(--layout-size);
    min-height: 40px;
    margin: auto;
  }

  .Nav_app-Nav__personasList__rsQGs {
    display: flex;
    flex-grow: 1;
    gap: 0 var(--sk-space-24);
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem__HuBH4 {
    display: flex;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    position: relative;
    padding: var(--sk-space-8) 0;
    color: var(--sk-color-black);
    font-size: var(--typo-body-3-font-size);
    line-height: var(--typo-body-3-line-height);
    text-decoration: none;
    text-transform: capitalize;
  }

  .Nav_app-Nav__personasLink--active__rGZ4z {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__personasLink__XjLP_:not(.Nav_app-Nav__personasLink--active__rGZ4z) {
    color: var(--sk-color-white);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover,
  .Nav_app-Nav__personasLink--active__rGZ4z {
    color: var(--color-primary);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover::after,
  .Nav_app-Nav__personasLink--active__rGZ4z::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--color-primary);
  }

  .Nav_app-Nav__personasLinkIcon__7ECJk {
    display: none;
  }

  .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  .Nav_app-Nav__link__W3ONF {
    display: flex;
    align-items: center;
    height: var(--sk-space-64);
    padding: 0 0.75rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-align: center;
  }

  .Nav_app-Nav__link--active__HuxqT {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__link__W3ONF:hover::after,
  .Nav_app-Nav__link--active__HuxqT::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    background-color: var(--themeButtonTextColor, var(--color-primary));
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--offers__u7Yqo {
  order: 1;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--companies__QU_5_ {
  order: 2;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--events__IblND {
  order: 3;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--community__17gx_ {
  order: 4;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobs__BR2xW {
  order: 5;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobRoles__aFmJi {
  order: 5;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--advices__D7eCm {
  order: 6;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--handbook__rAQDn {
  order: 7;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--ressources__aK2kz {
  order: 8;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--appointment__J_xa_ {
  order: 9;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--careerExplorer__q_aL5 {
  order: 10;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--login__OX5tA {
  order: 11;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
  color: var(--sk-color-black);
  font-weight: var(--font-weight-regular);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT {
  font-weight: var(--font-weight-semibold);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__linkLang__MY7Bx {
  color: var(--sk-color-white);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: column;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
    width: 100%;
    border-bottom-color: var(--sk-color-grey-100);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__item--dashboard__1NSLW {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF:hover::after,
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT::after {
    background-color: var(--sk-color-black);
  }
}

}
@layer base {
  .DropdownCommunity_hdr-DropdownCommunity__X_wja {
  display: inline-block;
  position: relative;
  outline: 0;
  cursor: pointer;
}

.DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
  position: static;
}

.DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
  width: 100%;
}

.DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 80%;
  right: 0;
  box-sizing: border-box;
  min-width: 270px;
  padding: 0 1.875rem 1.875rem;
  transition: visibility 0.5s;
  opacity: 0;
  pointer-events: auto;
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw,
.DropdownCommunity_hdr-DropdownCommunity__content___1isw:hover {
  display: block;
  visibility: visible;
  position: static;
  opacity: 1;
  background-color: #ffffff;
  box-shadow: var(--shadow-default);
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
  position: absolute;
  top: 105%;
  width: 98%;
  margin: 0 1%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.DropdownCommunity_hdr-DropdownCommunity__item__by3SB {
  list-style-type: none;
}

/** Dropdown link content */

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
  display: flex;
  align-items: center;
  color: var(--themeButtonTextColor);
  text-decoration: none;
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j:hover {
  background-color: var(--sk-color-grey-025);
  color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j::after {
  background-color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__linkTextWrapper__d37Kl {
  margin: 0;
}

.DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--themeMainTextColor);
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD {
  display: flex;
  align-items: center;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD svg {
  padding-left: var(--sk-space-4);
}

@media (max-width: 47.999rem) {
  /* --small-and-less-only */
  .DropdownCommunity_hdr-DropdownCommunity--nav__bQosD > svg {
    display: none;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
    position: relative;
  }

  .DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
    width: inherit;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    display: block;
    min-width: 345px;
    padding: 0;
    cursor: initial;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content--left__6B9lO {
    right: inherit;
    left: 0;
  }

  .DropdownCommunity_DropdownCommunity__list__OS6Fe {
    padding: 8px;
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    position: absolute;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
    position: absolute;
    top: 80%;
    width: inherit;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
    padding: 8px;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--sk-color-black);
}

}
@layer base {
  .Header_main__Zk9sm {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  --color-primary: #4dc942; /* Legacy Green */
  --shadow-default: 0 4px 8px rgb(118 118 120 / 20%);
  position: relative;
  z-index: var(--z-index-website-menu);
  background-color: var(--themeMainColor, var(--sk-color-white));
  box-shadow: var(--shadow-default);
}

.Header_nav__IP0B3 {
  --layout-size: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: var(--layout-size);
}

.Header_link__lueRy {
  color: var(--color-grey--900);
  text-decoration: none;
}

.Header_logo___onO5 {
  display: flex;
  grid-column-start: 1;
  align-items: center;
  height: var(--sk-space-64);
}

.Header_dropdown__b9_DX {
  display: flex;
  grid-column-start: 2;
}

.Header_topNav__T8_X2 {
  grid-column: 1 / span 2;
  grid-row-start: 2;
}

.Header_primaryNav__lM5sM {
  grid-column: 1 / span 2;
  grid-row-start: 3;
}

.Header_primaryNav__hidden__JAZy2 {
  visibility: hidden;
}

.Header_language__wLGkn {
  grid-column: 1 / span 2;
  grid-row-start: 4;
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */

  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open {
    position: fixed;
    z-index: inherit;
    top: 0;
    left: 0;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    background-color: inherit;
  }

  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_topNav__T8_X2,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_primaryNav__lM5sM,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_language__wLGkn,
  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open .Header_account__jNuE2 {
    display: none;
  }

  .Header_dropdown__b9_DX {
    flex-direction: row-reverse;
  }

  .Header_primaryNav__lM5sM {
    padding-top: var(--sk-space-16);
    padding-bottom: var(--sk-space-40);
  }

  .Header_topNav__T8_X2 + .Header_primaryNav__lM5sM {
    display: flex;
    align-items: flex-end;
    padding-top: var(--sk-space-48);
  }

  .Header_dropdownButton__Geqm7 {
    display: flex;
    margin-right: var(--sk-space-12);
  }

  .Header_app-Header__dropdownMenu__Lice9 {
    visibility: hidden;
    position: absolute;
    width: 100%;
  }

  .Header_language__wLGkn {
    padding: var(--sk-space-32) var(--sk-space-24);
  }
}

/* Only for devices with small screen height */

@media (max-height: 699px) and (max-width: 1023px) {
  .Header_language__wLGkn {
    padding-top: var(--sk-space-48);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Header_main__fixed__saFy6 {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }

  .Header_nav__IP0B3 {
    --layout-size: var(--mj-breakpoint);
    box-sizing: border-box;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto auto;
    margin: auto;
    padding: 0 var(--sk-space-24);
  }

  .Header_logo___onO5 {
    grid-column: 1;
    grid-row-start: 2;
  }

  .Header_app-Header__logo__Ikfct {
    height: var(--sk-space-32);
  }

  .Header_dropdown__b9_DX {
    grid-column: 3;
    grid-row-start: 2;
  }

  .Header_topNav__T8_X2 {
    grid-column: 1 / span 4;
    grid-row-start: 1;
  }

  .Header_primaryNav__lM5sM {
    grid-column: 2;
    grid-row-start: 2;
    text-align: right;
  }

  .Header_language__wLGkn {
    display: flex;
    grid-column: 4;
    grid-row-start: 2;
  }

  .Header_dropdownButton__Geqm7 {
    display: none;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .Header_main__Zk9sm {
  --color-primary: var(--sk-color-green-500);
  background-color: var(--sk-color-white);
}

[data-theme="jobteaser-uishift"] .Header_logo___onO5 path {
  fill: var(--sk-color-black);
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }
}

/* we just display the logo on the printed version of the page */

@media print {
  .Header_nav__IP0B3 > :not(.Header_logo___onO5) {
    display: none;
  }

  .Header_logo___onO5 {
    height: var(--sk-space-48);
    margin: auto;
  }
}

}
@layer base {
  .RadioRemote_option__LIM77 {
  display: block;
}

.RadioRemote_option__LIM77:not(:last-child) {
  margin-bottom: var(--spacer-200);
}

.RadioRemote_radioGroup__jbjqF {
  margin-bottom: var(--spacer-150);
}

.RadioRemote_field__f9oPf {
  margin-bottom: var(--spacer-400);
}

.RadioRemote_title__uHNw_ {
  margin-bottom: var(--spacer-200);
}

}
@layer base {
  .RichTextDescription_descriptionLabel__pn8Ew {
  display: block;
}

.RichTextDescription_RichTextEditor--loading__wNYgB {
  width: 100%;
  height: 343px;
}

.RichTextDescription_wrapper__VlG_8 .ql-toolbar {
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 0;
  background-color: var(--color-white);
}

.RichTextDescription_wrapper__VlG_8 .ql-editor {
  --RichTextEditor-min-height: 300px;
  min-height: var(--RichTextEditor-min-height);
  overflow-y: scroll;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
  resize: vertical;
}

.RichTextDescription_wrapper__VlG_8 .ql-size-large,
.RichTextDescription_wrapper__VlG_8 .ql-header .ql-picker-item[data-value="2"]::before,
.RichTextDescription_wrapper__VlG_8 .ql-editor h2 {
  margin: 0 0 var(--spacer-100);
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.RichTextDescription_wrapper__VlG_8 .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.RichTextDescription_wrapper__VlG_8 .ql-header .ql-picker-item[data-value="2"]::before {
  content: "Large";
}

.RichTextDescription_tipsWrapper___KBV0 {
  margin: var(--spacer-50) 0 0 var(--spacer-200);
  padding: 0;
}

.RichTextDescription_tipsItemBold__atXcs {
  font-weight: var(--bold);
}

.RichTextDescription_tips__B8b71 {
  margin-bottom: var(--spacer-400);
}

.RichTextDescription_field__LLSmx {
  margin-bottom: var(--spacer-25);
}

}
@layer base {
  .RadioBox_RadioBox__wDqPU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacer-200);
  border: solid 2px transparent;
  border-radius: var(--radius-100);
  outline: var(--border);
  text-align: center;
  cursor: pointer;
}

.RadioBox_RadioBox__wDqPU:has(input:focus-visible) {
  outline-color: var(--color-black);
  outline-width: 2px;
  box-shadow: 0 0 4px 2px var(--color-grey--200);
}

.RadioBox_RadioBox--checked__ZR84p {
  outline-color: var(--color-grey--500);
  outline-width: 2px;
}

.RadioBox_RadioBox__icon__xVlJP {
  margin-bottom: var(--spacer-200);
}

.RadioBox_RadioBox__main-label__ibiTP {
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

.RadioBox_RadioBox__input__PBPkq {
  position: absolute;
  opacity: 0;
}

.RadioBox_RadioBox__tagWrapper__e8uMP {
  margin-top: var(--spacer-100);
}

}
@layer base {
  .RadioProcess_RadioProcess__rdlbV {
  display: flex;
  flex-direction: column;
}

.RadioProcess_RadioProcess__rdlbV:has(+ *) {
  margin-bottom: var(--spacer-300);
}

.RadioProcess_SubFormContainer___nA0M {
  padding: var(--spacer-300) 0 0 var(--spacer-600);
  border: 0;
}

.RadioProcess_RadioBoxes__d0V6d {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacer-200);
}

.RadioProcess_Label__Y3My1 {
  display: inline-block;
  margin-bottom: var(--spacer-200);
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

.RadioProcess_option__ld2V_ {
  margin-top: var(--spacer-300);
}

.RadioProcess_message__oYWlH {
  margin-bottom: var(--spacer-200);
}

}
@layer base {
  .SelectTargetedAudience_field__w_igK {
  margin-bottom: var(--spacer-25);
}

}
@layer base {
  .PrivateJobAdCandidacy_JobAdCandidacy-Section--custom-height__MGMDN {
  --increase-height: 0;

  height: var(--increase-height);
}

}
@layer base {
  .PreviewDetails_description__kQVAc > p,
.PreviewDetails_description__kQVAc > ol,
.PreviewDetails_description__kQVAc > ul {
  margin: 0;
}

/* Handle title sizes from RichText in description */
.PreviewDetails_description__kQVAc {
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.PreviewDetails_description__kQVAc .ql-size-large,
.PreviewDetails_description__kQVAc h2 {
  margin: 0 0 var(--spacer-100);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.PreviewDetails_companyContainer__Kw_q_ {
  display: flex;
  gap: var(--spacer-300);
  grid-column: 1 / 3;
  align-items: center;
}

.PreviewDetails_companyLogo__sJSFy {
  position: relative;
  width: 64px;
  height: 64px;
}

.PreviewDetails_companyName__ehOtq {
  font-weight: var(--bold);
}

.PreviewDetails_divider__03dXL {
  margin-top: var(--spacer-400);
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .RadioWithOptionalTooltip_main__Label--disabled__caDCx {
  color: var(--color-grey--500);
}

.RadioWithOptionalTooltip_main__Label__nI_Pe {
  font-size: var(--subheadSmall);
  font-weight: var(--extraBold);
  line-height: var(--subheadSmallLineHeight);
}

}
@layer base {
  .SelectPublicationDuration_field__8fWCH {
  margin-top: var(--spacer-200);
  margin-bottom: var(--spacer-25);
}

}
@layer base {
  .SuggestedAction_suggestedAction__wrapper__G4eVP {
  margin-top: var(--spacer-100);
}

.SuggestedAction_main__KYePK {
  word-break: break-word;
}

.SuggestedAction_main--bordered__5BsOB {
  border: var(--border);
  border-color: var(--color-black);
}

.SuggestedAction_main__KYePK [class*="Text--subhead"] {
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

}
@layer base {
  .OfferCard_main__L9Jk1 {
  --OfferList-left-align: calc(var(--spacer-300) + var(--spacer-50));

  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
  min-width: var(--OfferCard-min-width);
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--spacer-200);
  border: var(--border);
  border-radius: var(--radius-100);
  gap: var(--spacer-100);
  background-color: var(--color-white);
}

.OfferCard_main--disabled__fCYcm {
  cursor: not-allowed;
}

.OfferCard_main__L9Jk1:not(.OfferCard_main--disabled__fCYcm):has(input:focus-visible) {
  border-color: var(--color-grey--500);
  outline: solid var(--color-grey--500) 0.0625rem; /* 1px */
}

.OfferCard_main__L9Jk1:not(.OfferCard_main--disabled__fCYcm) {
  cursor: pointer;
}

.OfferCard_main--error__GXrxV {
  border-color: var(--color-error--500);
}

}
@layer base {
  .OfferCardWrapper_main__AekXX {
  --OfferCard-min-width: 200px;

  display: grid;
  gap: var(--spacer-300);
  grid-template-columns: repeat(auto-fit, minmax(var(--OfferCard-min-width), 1fr));
}

}
@layer base {
  .OfferCards_error__cLMIu {
  margin-top: var(--spacer-500);
}

}
@layer base {
  .RadioTargetedAudience_RadioProcess__9A8UF {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-200);
}

.RadioTargetedAudience_RestrictedToSomeSchools__31JC3 {
  /* This allow to get 28px has left offset (24px + 4px) */
  --Offset-radioButton-text: calc(var(--spacer-300) + var(--spacer-50));

  margin-left: var(--Offset-radioButton-text);
}

.RadioTargetedAudience_schoolsText__gzfKN {
  margin-bottom: var(--spacer-200);
}

}
@layer base {
  .PreviewOffer_text__EqR6z {
  margin-bottom: var(--spacer-300);
}

}
@layer base {
  .SelectCompany_wrapper__jh1zW {
  display: flex;
  gap: var(--spacer-100);
  padding: var(--spacer-150) var(--spacer-200);
  transition: background-color 200ms;
}

.SelectCompany_wrapper__jh1zW:hover {
  background-color: var(--color-grey--050);
}

.SelectCompany_logo__Ln8Fx {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: var(--radius-100);
  background-color: var(--color-grey--100);
}

.SelectCompany_nameWrapper__FNwQm {
  margin-bottom: var(--spacer-25);
}

.SelectCompany_name__0Lsjg {
  font-weight: bold;
}

.SelectCompany_tag__UdUgr {
  margin-left: var(--spacer-100);
}

}
@layer base {
  .SelectCompany_createCompanyAction___n1YW {
  margin-left: 50%;
  transform: translateX(-50%);
}

.SelectCompany_field__IW8Hd {
  margin-bottom: var(--spacer-25);
}

.SelectCompany_updateCompanyList__GiVt_ {
  margin-top: var(--spacer-100);
  font-size: var(--sk-font-size-1);
  font-weight: var(--bold);
}

.SelectCompany_tips__vs6o1 {
  margin-bottom: var(--spacer-300);
}

}
@layer base {
  .SelectContractTypes_field__zea_3 {
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .SelectPositionCategory_field__PY9Gl {
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .SelectTags_main__MHniJ {
  position: relative;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
}

.SelectTags_field__eEVAb {
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .CCJobAdCandidacy_JobAdCandidacy-Section--custom-height__kZRsl {
  --increase-height: 0;

  height: var(--increase-height);
}

}
@layer base {
  .InvitationModal_radioGroupLegend__kihWN,
.InvitationModal_inputLabel__sFFfB {
  margin-bottom: var(--sk-space-16);
}

.InvitationModal_radioGroup__21LBY {
  display: flex;
  gap: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

}
@layer base {
  .BlogCard_card__5FUhZ {
  position: relative;
}

.BlogCard_cardImageWrapper__sYtBP {
  display: block;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: var(--radius-200);
}

.BlogCard_cardImage__NwBZw {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.BlogCard_category__ilRy3:not(:first-child) {
  margin-top: var(--spacer-200);
}

.BlogCard_title__Entrj:not(:first-child) {
  margin-top: var(--spacer-200);
}

.BlogCard_cardLink__uQLvH {
  margin-top: var(--spacer-200);
  color: var(--color-black);
  text-decoration: none;
}

.BlogCard_summary__gnbSm {
  margin: var(--spacer-100) 0 0;
}

/* TYPE: ARTICLE */
.BlogCard_card--article__w6fAs .BlogCard_cardLink__uQLvH::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* TYPE: GUIDE */
.BlogCard_card--guide__X_YOD {
  padding: var(--spacer-200);
  border: 1px solid var(--color-grey--100);
  border-radius: var(--radius-200);
}

@media (min-width: 64rem) {
  /* 2 COLUMNS */
  .BlogCard_card--2cols__7N2p0 {
    display: grid;
    gap: var(--spacer-100) var(--spacer-200);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_cardImageWrapper__sYtBP {
    grid-column: 1;
    grid-row: 1/5;
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_title__Entrj {
    grid-column: 2;
    grid-row: 2/5;
    margin: 0;
    font-size: var(--body);
    line-height: var(--bodyLineHeight);
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_cardLink__uQLvH {
    margin: 0;
  }

  .BlogCard_card--2cols__7N2p0 .BlogCard_category__ilRy3 {
    grid-column: 2;
    grid-row: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0 !important;
  }
}

}
@layer base {
  .ContactsInfo_contactTitle__slxDC {
  margin-bottom: var(--spacer-200);
  font-size: var(--title4);
  line-height: var(--title4-lh);
}

.ContactsInfo_info__OcUlg + .ContactsInfo_info__OcUlg {
  margin-top: var(--spacer-300);
}

.ContactsInfo_divider__v7ESo {
  margin-top: var(--spacer-400);
}

}
@layer base {
  /* Util space classes */
.Form_marginTop__FJcH_ {
  margin-top: var(--spacer-300);
}

.Form_marginLeft___ZSlW {
  margin-left: var(--spacer-300);
}

.Form_smallMarginLeft__YEgTe {
  margin-left: var(--sk-space-12);
}

/* Block title + buttons */
.Form_heading__OYN5R {
  flex: 1 0 auto;
  align-self: center;
  margin: 0;
}

.Form_headingWrapper__iOGgs,
.Form_sectionTitleWrapper__UgSL5 {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--sk-space-32);
  text-align: left;
  place-content: center space-between;
  gap: var(--sk-space-8);
}

.Form_headingWrapper__iOGgs:not(:first-of-type) {
  margin-top: var(--sk-space-32); /* For Team member title */
}

.Form_longButtons__xEHfp,
.Form_shortButtons__0pluC {
  display: flex;
  flex: 0 1 auto;
  gap: var(--sk-space-16);
  align-items: center;
  justify-content: flex-start;
  text-align: right;
}

/* Page top stepper */
.Form_stepper__rR_Fg {
  --Stepper-FulfilledColor: var(--color-black);
}

.Form_stepDisabled__YnbJj {
  cursor: default;
}

/* Form sections - matching side navigation */
.Form_section__kWswL + .Form_section__kWswL {
  margin-top: var(--sk-space-48);
}

.Form_tips__AascT {
  margin-bottom: var(--sk-space-24);
}

.Form_sectionTitle__lEwQY {
  margin-bottom: var(--sk-space-24);
}

.Form_titleContainer__pg_c3 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacer-150);
}

.Form_fieldContainer__dpGgM {
  margin-bottom: var(--sk-space-32);
}

/* Sub-sections (section content split into groups, in Contacts and Teams */
.Form_sectionContent__cV4Sg {
  padding: var(--sk-space-32);
}

.Form_sectionContent__cV4Sg + .Form_sectionContent__cV4Sg {
  margin-top: var(--sk-space-24);
}

@media (min-width: 768px) {
  .Form_sectionTitle__lEwQY,
  .Form_reviewSectionTitle___6Lpl,
  .Form_heading__OYN5R {
    --sk-typography-title-3-font-size: var(--sk-typography-title-2-font-size);
    --sk-typography-title-3-line-height: var(--sk-typography-title-2-line-height);
  }

  .Form_longButtons__xEHfp {
    margin: 0;
  }

  .Form_shortButtons__0pluC {
    justify-content: flex-end;
  }
}

}
@layer base {
  .Header_headerImageContainer__xwXaf {
  aspect-ratio: 7/2;
}

.Header_headerImage__ksvKL {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-100);
  -o-object-fit: cover;
     object-fit: cover;
}

.Header_welcomeTitle__label__rSSZ0 {
  margin-top: var(--spacer-200);
}

}
@layer base {
  .Quotes_quoteInfo__Ui_B_ + .Quotes_quoteInfo__Ui_B_ {
  margin-top: var(--spacer-300);
}

}
@layer base {
  .RecommendedActions_ctaContainer__Ty4Td + .RecommendedActions_ctaContainer__Ty4Td {
  margin-top: var(--spacer-300);
}

.RecommendedActions_ctaTitle__yS4gF {
  margin-bottom: var(--spacer-200);
}

.RecommendedActions_cta__04RRp + .RecommendedActions_cta__04RRp {
  margin-top: var(--spacer-300);
}

.RecommendedActions_divider__tq3yN {
  margin-top: var(--spacer-400);
}

}
@layer base {
  .Services_service__Cpsw6 + .Services_service__Cpsw6 {
  margin-top: var(--spacer-300);
}

}
@layer base {
  .Teams_grid__rNKSB {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--sk-space-16);
}

.Teams_teamsTitle__KQx2w {
  margin-bottom: var(--spacer-200);
}

.Teams_teamName__pDDXf {
  margin-bottom: var(--spacer-300);
}

.Teams_members__fJnfd {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-24);
}

.Teams_teamMemberSubtitle___GnVT {
  margin-bottom: var(--spacer-200);
}

.Teams_staffImage__UxNfz {
  width: 100%;
  max-width: var(--sk-size-56);
  height: auto;
  border-radius: var(--sk-radius-4);
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1;
}

.Teams_team__FodMb + .Teams_team__FodMb {
  margin-top: var(--spacer-300);
}

.Teams_divider__JCxUR {
  margin-top: var(--spacer-400);
}

@media (min-width: 30rem) {
  .Teams_grid__rNKSB {
    gap: var(--sk-space-24);
  }

  .Teams_members__fJnfd {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 64rem) {
  .Teams_grid__rNKSB {
    grid-template-columns: 2fr 5fr 5fr;
  }

  .Teams_members__fJnfd {
    grid-template-columns: 1fr;
  }
}

}
@layer base {
  .NewLanguage_container__c_93_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer-300);
  padding: var(--spacer-500);
  border: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.NewLanguage_buttonsContainer__oW0BS {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-200);
}

@media (min-width: 48rem) {
  .NewLanguage_container__c_93_ {
    padding: var(--spacer-1000);
  }

  .NewLanguage_heading__agdLl {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer base {
  .PagesStatus_carousel__ZQbjL {
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  border: var(--border);
  border-radius: var(--radius-100);
}

.PagesStatus_item__WoPIe {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: var(--spacer-200) var(--spacer-300);
  overflow: hidden;
  border: none;
  background: transparent;
  font-family: var(--fontFamily);
  text-align: inherit;
  cursor: pointer;
}

/* setting borders on the first and last items prevents the hover pseudo-element to overflow the card */
/* we can't set an overflow: hidden on the Carousel because it would hide the Carousel arrows */

.PagesStatus_item__first__XG5X3 {
  border-radius: var(--radius-100) 0 0 var(--radius-100);
}

.PagesStatus_item__last__IQnUC {
  border-radius: 0 var(--radius-100) var(--radius-100) 0;
}

.PagesStatus_item__WoPIe:hover::after,
.PagesStatus_item__active__4ThSu::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
}

.PagesStatus_item__WoPIe:hover::after {
  background: var(--color-grey--200);
}

.PagesStatus_item__active__4ThSu::after,
.PagesStatus_item__active__4ThSu:hover::after {
  background: var(--color-black);
}

.PagesStatus_separator__nWorZ {
  flex-shrink: 0;
  width: 1px;
  margin: var(--spacer-200) 0;
  background-color: var(--color-grey--100);
}

.PagesStatus_date__d_EsK {
  color: var(--color-grey--600);
}

}
@layer base {
  .MenuDropdown_dropdown__iM4YR {
  width: 100%;
}

.MenuDropdown_triggerButton__YJCKf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-color: transparent;
  background-color: transparent;
  color: black;
  gap: var(--spacer-100);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  text-align: left;
}

.MenuDropdown_triggerButton__selected__MR7xG {
  background-color: var(--color-black);
  color: var(--color-white);
}

.MenuDropdown_triggerButton__YJCKf:hover {
  background-color: var(--color-grey--100);
}

/* reset CSS from Dropdown's Button component */
.MenuDropdown_triggerButton__YJCKf:hover::before {
  background-color: transparent;
}

.MenuDropdown_triggerButton__YJCKf:focus {
  border-color: var(--color-black);
  background-color: transparent;
}

.MenuDropdown_triggerButton__selected__MR7xG:hover {
  background-color: var(--color-grey--800);
}

.MenuDropdown_triggerButton__selected__MR7xG:focus {
  background-color: var(--color-black);
}

.MenuDropdown_triggerButtonInitial__Nenpk {
  width: var(--spacer-300);
  height: var(--spacer-300);
  border-radius: var(--radius-900);
  background-color: var(--color-grey--100);
  color: var(--color-black) !important;
  font-size: 10px;
  text-align: center;
}

.MenuDropdown_triggerButton__YJCKf:not(.MenuDropdown_triggerButton__selected__MR7xG):hover .MenuDropdown_triggerButtonInitial__Nenpk {
  background-color: var(--color-grey--200);
}

.MenuDropdown_triggerButtonText__ICZyM {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuDropdown_triggerButtonArrowWithTag__JsOD8 {
  display: flex;
  align-items: center;
  gap: var(--spacer-25);
}

.MenuDropdown_triggerButtonText___hidden__BxAoZ {
  display: none;
}

.MenuDropdown_dropdownUserMenuItemLanguage__9Sune {
  display: flex;
  justify-content: space-between;
  border-bottom: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownUserMenuItemCurrentLocale__ER_l2 {
  text-transform: uppercase;
}

.MenuDropdown_dropdownConsumptionMenuItem__5FgLt {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-200);
  pointer-events: none;
}

.MenuDropdown_dropdownConsumptionMenuItemLink__j_YkA {
  border-top: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownConsumptionMenuItemValue___FOWd {
  font-weight: var(--semiBold);
}

.MenuDropdown_newTag__4GObg {
  margin-left: var(--spacer-200);
}

}
