@layer base {
  .main__Label--disabled {
  color: var(--color-grey--500);
}

.main__Label {
  font-size: var(--subheadSmall);
  font-weight: var(--extraBold);
  line-height: var(--subheadSmallLineHeight);
}

}