@layer base {
  /**
 * FilterBar component is used to wrap Filters and Select. As it override some styles on children, It suffers same issue
 * as Select (src/modules/uiKitOverrides/SelectFilter/Select.module.css), and need some !important in nextjs context.
 */

.filterBar [class*="jds-FilterBar__element"] {
  margin: 0 0 -1px;
}

.filterBar [class*="jds-Select__control"] {
  min-height: 48px !important;
}

@media (--medium-viewport) {
  .filterBar [class*="jds-FilterBar__element"] {
    margin: 0 -1px -1px 0;
  }

  .filterBar [class*="jds-FilterBar__element"]:first-child [class*="jds-Select__control"],
  .filterBar [class*="jds-FilterBar__element"]:first-child [class*="jds-Input"] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .filterBar [class*="jds-FilterBar__element"]:last-child [class*="jds-Select__control"],
  .filterBar [class*="jds-FilterBar__element"]:last-child [class*="jds-Input"] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .filterBar [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Select__control"],
  .filterBar [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Input"] {
    border-radius: 0 !important;
  }
}

}