@layer base {
  .main {
  --OfferList-left-align: calc(var(--spacer-300) + var(--spacer-50));

  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
  min-width: var(--OfferCard-min-width);
  height: fit-content;
  padding: var(--spacer-200);
  border: var(--border);
  border-radius: var(--radius-100);
  gap: var(--spacer-100);
  background-color: var(--color-white);
}

.main--disabled {
  cursor: not-allowed;
}

.main:not(.main--disabled):has(input:focus-visible) {
  border-color: var(--color-grey--500);
  outline: solid var(--color-grey--500) 0.0625rem; /* 1px */
}

.main:not(.main--disabled) {
  cursor: pointer;
}

.main--error {
  border-color: var(--color-error--500);
}

}