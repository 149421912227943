@layer base {
  .RadioProcess {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-200);
}

.RestrictedToSomeSchools {
  /* This allow to get 28px has left offset (24px + 4px) */
  --Offset-radioButton-text: calc(var(--spacer-300) + var(--spacer-50));

  margin-left: var(--Offset-radioButton-text);
}

.schoolsText {
  margin-bottom: var(--spacer-200);
}

}