@layer base {
  /* Util space classes */
.marginTop {
  margin-top: var(--spacer-300);
}

.marginLeft {
  margin-left: var(--spacer-300);
}

.smallMarginLeft {
  margin-left: var(--sk-space-12);
}

/* Block title + buttons */
.heading {
  flex: 1 0 auto;
  align-self: center;
  margin: 0;
}

.headingWrapper,
.sectionTitleWrapper {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--sk-space-32);
  text-align: left;
  place-content: center space-between;
  gap: var(--sk-space-8);
}

.headingWrapper:not(:first-of-type) {
  margin-top: var(--sk-space-32); /* For Team member title */
}

.longButtons,
.shortButtons {
  display: flex;
  flex: 0 1 auto;
  gap: var(--sk-space-16);
  align-items: center;
  justify-content: flex-start;
  text-align: right;
}

/* Page top stepper */
.stepper {
  --Stepper-FulfilledColor: var(--color-black);
}

.stepDisabled {
  cursor: default;
}

/* Form sections - matching side navigation */
.section + .section {
  margin-top: var(--sk-space-48);
}

.tips {
  margin-bottom: var(--sk-space-24);
}

.sectionTitle {
  margin-bottom: var(--sk-space-24);
}

.titleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacer-150);
}

.fieldContainer {
  margin-bottom: var(--sk-space-32);
}

/* Sub-sections (section content split into groups, in Contacts and Teams */
.sectionContent {
  padding: var(--sk-space-32);
}

.sectionContent + .sectionContent {
  margin-top: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .sectionTitle,
  .reviewSectionTitle,
  .heading {
    --sk-typography-title-3-font-size: var(--sk-typography-title-2-font-size);
    --sk-typography-title-3-line-height: var(--sk-typography-title-2-line-height);
  }

  .longButtons {
    margin: 0;
  }

  .shortButtons {
    justify-content: flex-end;
  }
}

}