@layer base {
  .headerImageContainer {
  aspect-ratio: 7/2;
}

.headerImage {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-100);
  object-fit: cover;
}

.welcomeTitle__label {
  margin-top: var(--spacer-200);
}

}