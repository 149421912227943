@layer base {
  .title {
  margin-bottom: var(--sk-space-24);
}

.note {
  margin-bottom: var(--sk-space-24);
}

.buttons {
  display: flex;
  gap: var(--sk-space-8);
  flex-wrap: wrap;
  margin-top: var(--sk-space-32);
}

.button {
  width: 100%;
}

.dataTextTitle {
  margin-top: var(--sk-space-24);
}

@media (--small-viewport) {
  .button {
    width: auto;
  }
}

}