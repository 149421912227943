@layer base {
  .main {
  padding-top: var(--spacer-400);
  padding-bottom: var(--spacer-400);
  background: linear-gradient(0deg, transparent 50%, var(--color-black) 50%);
}

@media (--medium-viewport) {
  .main {
    padding-top: var(--spacer-800);
    padding-bottom: var(--spacer-800);
  }
}

}