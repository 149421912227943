@layer base {
  .contactTitle {
  margin-bottom: var(--spacer-200);
  font-size: var(--title4);
  line-height: var(--title4-lh);
}

.info + .info {
  margin-top: var(--spacer-300);
}

.divider {
  margin-top: var(--spacer-400);
}

}