@layer base {
  .ctaContainer + .ctaContainer {
  margin-top: var(--spacer-300);
}

.ctaTitle {
  margin-bottom: var(--spacer-200);
}

.cta + .cta {
  margin-top: var(--spacer-300);
}

.divider {
  margin-top: var(--spacer-400);
}

}