@layer base {
  .descriptionLabel {
  display: block;
}

.RichTextEditor--loading {
  width: 100%;
  height: 343px;
}

.wrapper :global(.ql-toolbar) {
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 0;
  background-color: var(--color-white);
}

.wrapper :global(.ql-editor) {
  --RichTextEditor-min-height: 300px;
  min-height: var(--RichTextEditor-min-height);
  overflow-y: scroll;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
  resize: vertical;
}

.wrapper :global(.ql-size-large),
.wrapper :global(.ql-header .ql-picker-item[data-value="2"]::before),
.wrapper :global(.ql-editor h2) {
  margin: 0 0 var(--spacer-100);
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.wrapper :global(.ql-picker.ql-header .ql-picker-label[data-value="2"]::before),
.wrapper :global(.ql-header .ql-picker-item[data-value="2"]::before) {
  content: "Large";
}

.tipsWrapper {
  margin: var(--spacer-50) 0 0 var(--spacer-200);
  padding: 0;
}

.tipsItemBold {
  font-weight: var(--bold);
}

.tips {
  margin-bottom: var(--spacer-400);
}

.field {
  margin-bottom: var(--spacer-25);
}

}