@layer base {
  .featuresCol {
  width: 23.18%;
}

.packCol--limited {
  width: 15.54%;
}

.packCol--light,
.packCol--classic {
  width: 20.44%;
}

.packCol--performance {
  max-width: 20.4%;
}

}