@layer base {
  .main {
  padding: 0;
  vertical-align: bottom;
}

.thWrapper {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-32) var(--sk-space-24);
  border-bottom: solid var(--jt-subscriptionTable-border-color) 1px;
  border-top-left-radius: var(--jt-subscriptionTable-border-radius);
  border-top-right-radius: var(--jt-subscriptionTable-border-radius);
  background-color: var(--sk-color-white);
  vertical-align: bottom;
}

.main:nth-child(2) .thWrapper {
  border-bottom: none;
}

.title {
  margin-bottom: var(--sk-space-8);
}

.thBadge {
  margin: var(--sk-space-8) 0;
}

@media (--large-viewport) {
  .thWrapper,
  .main:nth-child(2) .thWrapper {
    border: solid var(--jt-subscriptionTable-border-color) 1px;
  }
}

}