@layer base {
  .main {
  position: relative;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
}

.field {
  margin-bottom: var(--spacer-400);
}

}