@layer base {
  .suggestedAction__wrapper {
  margin-top: var(--spacer-100);
}

.main {
  word-break: break-word;
}

.main--bordered {
  border: var(--border);
  border-color: var(--color-black);
}

.main [class*="Text--subhead"] {
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

}