@layer base {
  .label {
  color: var(--color-white);
}

@media (--medium-and-less-only) {
  .form {
    grid-column: 1;
    margin-top: var(--spacer-300);
  }
}

@media (--medium-viewport) {
  .fieldsWrapper {
    display: flex;
    gap: var(--spacer-150);
    align-items: flex-end;
  }

  .field {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

}