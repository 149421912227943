@layer base {
  .main {
  --jt-IconPackState-size: 1.75rem;

  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: var(--jt-IconPackState-size);
  height: var(--jt-IconPackState-size);
  padding: 0.4rem;
  border-radius: 100%;
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  font-size: 1rem;
}

.main--included {
  background-color: var(--sk-color-green-550);
}

.main--notIncluded {
  background-color: var(--sk-color-grey-200);
}

}