@layer base {
  .grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--sk-space-16);
}

.teamsTitle {
  margin-bottom: var(--spacer-200);
}

.teamName {
  margin-bottom: var(--spacer-300);
}

.members {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-24);
}

.teamMemberSubtitle {
  margin-bottom: var(--spacer-200);
}

.staffImage {
  width: 100%;
  max-width: var(--sk-size-56);
  height: auto;
  border-radius: var(--sk-radius-4);
  object-fit: cover;
  aspect-ratio: 1;
}

.team + .team {
  margin-top: var(--spacer-300);
}

.divider {
  margin-top: var(--spacer-400);
}

@media (--small-viewport) {
  .grid {
    gap: var(--sk-space-24);
  }

  .members {
    grid-template-columns: 1fr 1fr;
  }
}

@media (--large-viewport) {
  .grid {
    grid-template-columns: 2fr 5fr 5fr;
  }

  .members {
    grid-template-columns: 1fr;
  }
}

}