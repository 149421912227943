@layer base {
  .main {
  text-align: left;
}

.thCell,
.thEmptyCell {
  background-color: var(--sk-color-white);
}

.thCell {
  padding: var(--sk-space-8) 0;
}

.thEmptyCell {
  padding: 0 var(--sk-space-16);
}

.thEmptyCell,
.tdEmptyCell {
  display: none !important;
}

.colGroupTitle {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-450);
  color: var(--sk-color-white);
  text-align: center;
}

@media (--large-viewport) {
  .thEmptyCell,
  .tdEmptyCell {
    display: table-cell !important;
  }

  .colGroupTitle {
    width: inherit;
    text-align: left;
  }
}

}