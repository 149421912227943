@layer base {
  .main {
  --backgroundImage: unset;

  position: relative;
  margin-bottom: var(--sk-space-80);
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-400);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -200px -200px;
  color: var(--sk-color-white);
  text-shadow: var(--sk-color-purple-500) 0 0 0.625rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-32);
}

.cta {
  flex-shrink: 0;
  text-shadow: none;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

@media (--medium-viewport) {
  .main {
    padding: var(--sk-space-56) var(--sk-space-72);
  }

  .content {
    flex-direction: row;
    gap: var(--sk-space-56);
  }
}

}