@layer base {
  .card {
  position: relative;
}

.cardImageWrapper {
  display: block;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: var(--radius-200);
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.category:not(:first-child) {
  margin-top: var(--spacer-200);
}

.title:not(:first-child) {
  margin-top: var(--spacer-200);
}

.cardLink {
  margin-top: var(--spacer-200);
  color: var(--color-black);
  text-decoration: none;
}

.summary {
  margin: var(--spacer-100) 0 0;
}

/* TYPE: ARTICLE */
.card--article .cardLink::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* TYPE: GUIDE */
.card--guide {
  padding: var(--spacer-200);
  border: 1px solid var(--color-grey--100);
  border-radius: var(--radius-200);
}

@media (--large-viewport) {
  /* 2 COLUMNS */
  .card--2cols {
    display: grid;
    gap: var(--spacer-100) var(--spacer-200);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .card--2cols .cardImageWrapper {
    grid-column: 1;
    grid-row: 1/5;
  }

  .card--2cols .title {
    grid-column: 2;
    grid-row: 2/5;
    margin: 0;
    font-size: var(--body);
    line-height: var(--bodyLineHeight);
  }

  .card--2cols .cardLink {
    margin: 0;
  }

  .card--2cols .category {
    grid-column: 2;
    grid-row: 1;
    width: fit-content;
    height: fit-content;
    margin-top: 0 !important;
  }
}

}