@layer base {
  .description > p,
.description > ol,
.description > ul {
  margin: 0;
}

/* Handle title sizes from RichText in description */
.description {
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.description :global(.ql-size-large),
.description :global(h2) {
  margin: 0 0 var(--spacer-100);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.companyContainer {
  display: flex;
  gap: var(--spacer-300);
  grid-column: 1 / 3;
  align-items: center;
}

.companyLogo {
  position: relative;
  width: 64px;
  height: 64px;
}

.companyName {
  font-weight: var(--bold);
}

.divider {
  margin-top: var(--spacer-400);
  margin-bottom: var(--spacer-400);
}

}